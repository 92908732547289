.layout-header {
  display: flex;
  justify-content: center;
  width: 100%;
  &.home-header {
    // position: absolute;
    // z-index: 1;
    // background-color: transparent;
    // .ant-menu-horizontal > .ant-menu-item > a {
    //   color: #D5D5D5;
    // }
    // .header-main .logo .title {
    //   color: @primary-color;
    // }

    // .search {
    //   .ant-select-selection {
    //     background-color: rgba(0, 0, 0, 0.3);
    //     color: #D5D5D5;
    //     border: solid 1px rgba(0, 0, 0, 0.2);
    //   }

    //   .ant-select-selection {
    //     border-top-left-radius: 4px !important;
    //     border-bottom-left-radius: 4px !important;
    //   }
    //   .ant-input-group-addon {
    //     border-top-right-radius: 4px !important;
    //     border-bottom-right-radius: 4px !important;
    //   }
    //   .ant-select-arrow {
    //     color: #D5D5D5;
    //   }
    //   .ant-input {
    //     background-color: rgba(0, 0, 0, 0.3);
    //     border: solid 1px rgba(0, 0, 0, 0.2);
    //     color: #D5D5D5;
    //   }
    // }
  }
  .header-main {
    width: 1200px;
    padding: 0;
    height: 65px;
    display: flex;
    .logo {
      color: #717171;
      display: flex;
      align-items: center;
      margin-right: 30px;
      img {
        width: 40px;
      }
      .title {
        color: #717171;
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
    .ant-menu {
      .ant-menu-item {
        &:hover {
          color: @white;
          background-color: #1fa574;
        }
      }
    }

    .search {
    }
  }
}
.header__trigger {
  color: @white;
}
